import * as React from "react";
import FaqSection from "../components/index/faq-section";
import PageLayout from "../layouts/page-layout";

const FaqPage = () => {
  return (
    <PageLayout>
      <FaqSection />
    </PageLayout>
  );
};

export default FaqPage;
