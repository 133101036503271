import styled from "styled-components";
import * as React from "react";
import * as Accordion from "@radix-ui/react-accordion";
import PageWrapper from "../../page-wrapper";
import siteConfig from "../../../../site-config.json";

const FaqSectionWrapper = styled.section`
  padding: 48px 0;

  @media screen and (min-width: 600px) {
    padding: 96px 0;
  }
`;

const FaqSectionHeader = styled.div`
  font: var(--text-font-h2);

  h2 {
    font-size: 1em;
    font-weight: 700;
    margin: 0;
  }

  p {
    margin-top: 16px;
    margin-bottom: 32px;
    line-height: 160%;
    font-size: 0.45em;
    font-weight: 500;
    opacity: 0.5;

    .dark-mode & a {
      color: var(--theme-color-primary-text);
    }
  }
`;

const StyledAccordionItem = styled(Accordion.Item)`
  padding: 16px 0;
  border-bottom: 1px solid var(--theme-color-divider);

  &:last-of-type {
    border-color: transparent;
  }

  @media screen and (min-width: 600px) {
    padding: 32px 0;
  }
`;

const StyledAccordionHeader = styled(Accordion.Header)`
  margin: 0;
  font: var(--text-font-h2);
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledAccordionTrigger = styled(Accordion.Trigger)`
  --accordion-trigger-size: 46px;
  background: none;
  border: none;
  padding: 0;
  margin-left: 0.5em;
  cursor: pointer;
  color: var(--theme-color-primary);
  border: 1px solid var(--theme-color-border);
  border-radius: 50%;
  flex: 0 0 var(--accordion-trigger-size);
  width: var(--accordion-trigger-size);
  height: var(--accordion-trigger-size);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(0deg);
  transition: 0.3s transform, 0.1s color, 0.1s background-color,
    0.1s border-color;

  @media screen and (min-width: 400px) {
    --accordion-trigger-size: 56px;
  }

  &:after {
    content: "+";
  }

  &:hover {
    color: var(--theme-color-secondary);
    background-color: var(--theme-color-primary);
    border-color: var(--theme-color-primary);
  }

  &[data-state="open"] {
    color: var(--theme-color-secondary);
    background-color: var(--theme-color-primary);
    border-color: var(--theme-color-primary);
    transform: rotate(360deg);

    &:after {
      content: "\\2013";
      transform: translateY(-2px);
    }
  }

  .dark-mode & {
    background-color: var(--theme-color-secondary);
    color: rgba(255, 242, 201, 0.7);
  }

  .dark-mode &[data-state="open"],
  .dark-mode &:hover {
    background-color: var(--theme-color-primary);
  }
`;

const StyledAccordionContent = styled(Accordion.Content)`
  overflow: hidden;
  animation: 0.2s accordion forwards;

  &:before {
    content: "";
    display: block;
    height: 24px;
  }

  &[data-state="closed"] {
    animation: 0.2s accordion-out forwards;
  }

  @keyframes accordion {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes accordion-out {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }
`;

const FaqSection = () => {
  return (
    <FaqSectionWrapper>
      <PageWrapper>
        <FaqSectionHeader>
          <h2>Frequently Asked Questions</h2>
          <p>
            If you have any further questions about Procure and our services,
            our team is here to help. Feel free to reach out to us at{" "}
            <a href="mailto:hi@procure.biz">hi@procure.biz</a>, and we'll be
            happy to assist you in any way we can.
          </p>
        </FaqSectionHeader>
        <Accordion.Root collapsible>
          {siteConfig.faq.map((item, idx) => (
            <StyledAccordionItem value={`item-${idx}`}>
              <StyledAccordionHeader>
                {item.title}
                <StyledAccordionTrigger />
              </StyledAccordionHeader>
              <StyledAccordionContent>{item.content}</StyledAccordionContent>
            </StyledAccordionItem>
          ))}
        </Accordion.Root>
      </PageWrapper>
    </FaqSectionWrapper>
  );
};
export default FaqSection;
